<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        custodyExpenseOperations.custodyExpenseOperationsData != undefined &&
        custodyExpenseOperations.custodyExpenseOperationsData.length != 0
      "
    >
      <CustodyExpenseOperationsTable
        v-on:setCustodyExpenseOperation="
          custodyExpenseOperations.custodyExpenseOperation.fillData($event)
        "
        :custodyExpenseOperations="
          custodyExpenseOperations.custodyExpenseOperationsData
        "
        :pagination="custodyExpenseOperations.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="custodyExpenseOperations.pagination"
        v-on:changePage="changePage"
      />
      <CustodyExpenseOperationInfo
        :custodyExpenseOperation="
          custodyExpenseOperations.custodyExpenseOperation
        "
      />
      <CustodyExpenseOperationCancel
        :custodyExpenseOperation="
          custodyExpenseOperations.custodyExpenseOperation
        "
        v-on:refresh="getAllCustodyExpenseOperations()"
      />
    </div>

    <CustodyExpenseOperationBtns
      :theFilterData="custodyExpenseOperations.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustodyExpenseOperationsTable from "@/components/finance/custodyExpenseOperations/CustodyExpenseOperationsTable.vue";
import CustodyExpenseOperationInfo from "@/components/finance/custodyExpenseOperations/CustodyExpenseOperationInfo.vue";
import CustodyExpenseOperationCancel from "@/components/finance/custodyExpenseOperations/CustodyExpenseOperationCancel.vue";
import CustodyExpenseOperationBtns from "@/components/finance/custodyExpenseOperations/CustodyExpenseOperationBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import CustodyExpenseOperations from "@/models/finance/custodyExpenseOperations/CustodyExpenseOperations";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustodyExpenseOperationsTable,
    CustodyExpenseOperationInfo,
    CustodyExpenseOperationCancel,
    CustodyExpenseOperationBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      custodyExpenseOperations: new CustodyExpenseOperations(),
    };
  },
  props: ["custodyToken"],
  methods: {
    changePage(page) {
      this.custodyExpenseOperations.pagination.selfPage = page;
      this.getAllCustodyExpenseOperations();
    },
    search(data) {
      this.custodyExpenseOperations.filterData.fillData(data);
      this.custodyExpenseOperations.custodyExpenseOperationsData = [];
      this.getAllCustodyExpenseOperations();
    },
    async getAllCustodyExpenseOperations() {
      this.isLoading = true;
      try {
        const response =
          await this.custodyExpenseOperations.custodyExpenseOperation.getAllCustodyExpenseOperations(
            this.language,
            this.userAuthorizeToken,
            this.custodyExpenseOperations.pagination,
            this.custodyExpenseOperations.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.custodyExpenseOperations.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.custodyExpenseOperations.custodyExpenseOperationsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.custodyExpenseOperations.custodyExpenseOperationsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.custodyExpenseOperations.custodyExpenseOperationsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.custodyExpenseOperations.custodyExpenseOperationsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.custodyExpenseOperations.filterData.custodyToken = this.custodyToken
      ? this.custodyToken
      : "";
    this.getAllCustodyExpenseOperations();
  },
};
</script>
