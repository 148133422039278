<template>
  <b-modal
    id="CustodyExpenseOperationCancel"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/cancel.svg" class="icon-lg" />
        {{ $t("cancel") }}
      </h3>
    </template>

    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="custodyExpenseOperation.custodyNameCurrent"
        :title="$t('Custodies.theName')"
        :imgName="'Custodies.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyExpenseNameCurrent"
        :title="$t('CustodyExpenses.theName')"
        :imgName="'CustodyExpenses.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(custodyExpenseOperation.moneyPaid)"
        :title="$t('CustodyExpenseOperations.moneyPaid')"
        :imgName="'money.svg'"
      />

      <TextArea
        :className="'col-md-6'"
        :id="'expenseCancellationReasonAr'"
        :value="custodyExpenseOperation.expenseCancellationReasonAr"
        v-on:changeValue="
          custodyExpenseOperation.expenseCancellationReasonAr = $event
        "
        :title="$t('CustodyExpenseOperations.cancellationReasonAr')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'expenseCancellationReasonEn'"
        :value="custodyExpenseOperation.expenseCancellationReasonEn"
        v-on:changeValue="
          custodyExpenseOperation.expenseCancellationReasonEn = $event
        "
        :title="$t('CustodyExpenseOperations.cancellationReasonEn')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'expenseCancellationReasonUnd'"
        :value="custodyExpenseOperation.expenseCancellationReasonUnd"
        v-on:changeValue="
          custodyExpenseOperation.expenseCancellationReasonUnd = $event
        "
        :title="$t('CustodyExpenseOperations.cancellationReasonUnd')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'expenseCancellationNotes'"
        :value="custodyExpenseOperation.expenseCancellationNotes"
        v-on:changeValue="
          custodyExpenseOperation.expenseCancellationNotes = $event
        "
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>

    <div class="form-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="cancelCustodyExpenseOperation"
      >
        {{ $t("yes") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('CustodyExpenseOperationCancel')"
      >
        {{ $t("no") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import { moneyFormat } from "@/utils/functions";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    DataLabelGroup,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  methods: {
    async cancelCustodyExpenseOperation() {
      this.isLoading = true;
      try {
        let response =
          await this.custodyExpenseOperation.cancelCustodyExpenseOperation(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("CustodyExpenseOperationCancel");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    moneyFormat,
  },
  props: ["custodyExpenseOperation"],
  computed: {},
};
</script>
