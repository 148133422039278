<template>
  <b-modal
    id="CustodyExpenseOperationInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/Custodies.svg" class="icon-lg" />
        {{ $t("Custodies.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="custodyExpenseOperation.custodyNameCurrent"
        :title="$t('Custodies.theName')"
        :imgName="'Custodies.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="custodyExpenseOperation.custodyExpenseNameCurrent"
        :title="$t('CustodyExpenses.theName')"
        :imgName="'CustodyExpenses.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(custodyExpenseOperation.moneyPaid)"
        :title="$t('CustodyExpenseOperations.moneyPaid')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyRemainingValue"
        :title="$t('CustodyExpenseOperations.quantity')"
        :imgName="'number.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyExpenseOperationDescriptionAr"
        :title="$t('CustodyExpenseOperations.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyExpenseOperationDescriptionEn"
        :title="$t('CustodyExpenseOperations.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyExpenseOperationDescriptionUnd"
        :title="$t('CustodyExpenseOperations.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.custodyExpenseOperationNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custodyExpenseOperation.userMakeActionNameCurrent"
        :title="$t('userMakeAction')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            custodyExpenseOperation.dateActionAdd,
            custodyExpenseOperation.timeActionAdd
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <div class="my-card col-12">
        <span class="my-card-title">{{
          `${$t("CustodyExpenseOperations.cancellationStatus")}: (${
            custodyExpenseOperation.cancellationStatus ? $t("yes") : $t("no")
          })`
        }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custodyExpenseOperation.expenseCancellationReasonAr"
            :title="$t('CustodyExpenseOperations.cancellationReasonAr')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custodyExpenseOperation.expenseCancellationReasonEn"
            :title="$t('CustodyExpenseOperations.cancellationReasonEn')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custodyExpenseOperation.expenseCancellationReasonUnd"
            :title="$t('CustodyExpenseOperations.cancellationReasonUnd')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custodyExpenseOperation.custodySettlementOperationNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custodyExpenseOperation.userMakeCancellationNameCurrent"
            :title="$t('userMakeCancellation')"
            :imgName="'employees.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              custodyExpenseOperation.cancellationDate
                ? formateDateTimeLang(
                    custodyExpenseOperation.cancellationDate,
                    custodyExpenseOperation.cancellationTime
                  )
                : $t('notFound')
            "
            :title="$t('cancellationDateTime')"
            :imgName="'dateAndTime.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { moneyFormat, formateDateTimeLang } from "@/utils/functions";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["custodyExpenseOperation"],
  methods: {
    moneyFormat,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>
