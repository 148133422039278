import PaginationData from "@/models/general/PaginationData";
import CustodyExpenseOperation from "./CustodyExpenseOperation";
import CustodyExpenseOperationsFilter from "./CustodyExpenseOperationsFilter";

export default class CustodyExpenseOperations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.custodyExpenseOperationsData = [];
    this.custodyExpenseOperation = new CustodyExpenseOperation();
    this.filterData = new CustodyExpenseOperationsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.custodyExpenseOperationsPagination);
    this.custodyExpenseOperationsData =
      data.custodyExpenseOperationsPagination.custodyExpenseOperationsData;
  }
}
