var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.custodyExpenseOperations)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("Custodies.theName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("CustodyExpenses.theName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("CustodyExpenseOperations.moneyPaid")))]),_c('th',[_vm._v(_vm._s(_vm.$t("CustodyExpenseOperations.quantity")))]),_c('th',[_vm._v(_vm._s(_vm.$t("CustodyExpenseOperations.description")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),_c('th',[_vm._v(_vm._s(_vm.$t("info")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsCancelAll
              ) ||
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsCancelSelf
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.custodyExpenseOperations),function(operation,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.pagination.currentIndex))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(operation.custodyNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(operation.custodyExpenseNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.moneyFormat(operation.moneyPaid))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(operation.custodyExpenseOperationQuantity))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(operation.custodyExpenseOperationDescriptionCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(operation.custodyExpenseOperationNotes))+" ")]),_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.CustodyExpenseOperationInfo",modifiers:{"CustodyExpenseOperationInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":function($event){return _vm.setCustodyExpenseOperation(operation)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})])]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsCancelAll
              ) ||
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsCancelSelf
              )
            )?_c('td',[(!operation.expenseCancellationStatus)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.CustodyExpenseOperationCancel",modifiers:{"CustodyExpenseOperationCancel":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('cancel')},on:{"click":function($event){return _vm.setCustodyExpenseOperation(operation)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/cancel.svg")}})]):[_vm._v(_vm._s(_vm.$t("cancelDone")))]],2):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }