<template>
  <div v-if="custodyExpenseOperations" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Custodies.theName") }}</th>
            <th>{{ $t("CustodyExpenses.theName") }}</th>
            <th>{{ $t("CustodyExpenseOperations.moneyPaid") }}</th>
            <th>{{ $t("CustodyExpenseOperations.quantity") }}</th>
            <th>{{ $t("CustodyExpenseOperations.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("info") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsCancelAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsCancelSelf
                )
              "
            >
              {{ $t("cancel") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(operation, index) in custodyExpenseOperations"
            :key="index"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(operation.custodyNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(operation.custodyExpenseNameCurrent) }}
            </td>
            <td>
              {{ moneyFormat(operation.moneyPaid) }}
            </td>
            <td>
              {{ isDataExist(operation.custodyExpenseOperationQuantity) }}
            </td>
            <td>
              {{
                isDataExist(operation.custodyExpenseOperationDescriptionCurrent)
              }}
            </td>
            <td>
              {{ isDataExist(operation.custodyExpenseOperationNotes) }}
            </td>
            <td>
              <button
                v-b-modal.CustodyExpenseOperationInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setCustodyExpenseOperation(operation)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsCancelAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsCancelSelf
                )
              "
            >
              <button
                v-if="!operation.expenseCancellationStatus"
                v-b-modal.CustodyExpenseOperationCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="setCustodyExpenseOperation(operation)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
              <template v-else>{{ $t("cancelDone") }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { isDataExist, moneyFormat } from "./../../../utils/functions";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["custodyExpenseOperations", "pagination"],
  methods: {
    setCustodyExpenseOperation(data) {
      this.$emit("setCustodyExpenseOperation", data);
    },
    isDataExist,
    moneyFormat,
  },
  computed: {},
  async created() {},
};
</script>
